import { Box, Divider, Grid, styled } from "@mui/material"
import { Imgcustome } from "../../resources"
import imgsrc from '../../img/profile.png'
import { useEffect, useState } from "react"
import DialogPsw from "./DialogPsw"
import AccordionUsage from "./Accordion"
import useScreenSize from "../../functions/useScreenSize"
import { AlternateEmail, LocalPhone, Place } from "@mui/icons-material"


const Profile = ({mTglobal}) => {
    const {width, height} = useScreenSize()

    const [info, setInfo] = useState({
        correo:     "jyehovani@gmail.com",
        tel:        "8125230359",
        direccion:  "Calle San Carlos #120, col. San Genaro, Gral. Escobedo, N.L."
    })

    const [lenguaje, setLenguaje] = useState([
        {name: "Javascript", percent: 100},
        {name: "Python", percent: 80},
        {name: "Php", percent: 80},
        {name: "Java", percent: 25},
        {name: "Visual Basic", percent: 25}
    ])

    const [tech, setTech] = useState([
        {name: "ReactJs", percent: 100},
        {name: "ExpressJs", percent: 100},
        {name: "Flet", percent: 80},
        {name: "Django", percent: 60},
        {name: "React Native", percent: 50},
        {name: "CodeIgniter 3", percent: 50},
        {name: "Angular", percent: 20},
    ])

    const [adds, setAdds] = useState([
        {name: "HTML", percent: 100},
        {name: "CSS", percent: 100},
        {name: "Nginx", percent: 100},
        {name: "Git", percent: 100},
        {name: "AWS", percent: 70},
        {name: "PM2", percent: 90},
        {name: "Apache", percent: 60},
        {name: "Bootstrap", percent: 80},
        {name: "Material-UI", percent: 100},
        {name: "NodeJs", percent: 100},
        {name: "Liquid", percent: 50},
        {name: "META api", percent: 50},
        {name: "Tiktok Manager", percent: 50},
        {name: "Bitrix24", percent: 50},
        {name: "Wordpress", percent: 80},
    ])

    const [dbs, setDbs] = useState([
        {name: "MySql", percent: 70},
        {name: "MySqlite", percent: 70},
        {name: "MongoDB", percent: 90},
    ])

    const [pws, setPsw]     = useState("profile2024")
    const [copws, setCoPsw] = useState("")
    const [open, setOpen] = useState(true)

    useEffect(()=>{
        if(copws == pws){setOpen(false)}
    },[copws])

    const Parrafo = styled('p')({
        margin: "0px !important",
        textAlign: "center",
        fontSize: "12px",
        color: "white"
    })

    const Titulo = styled('p')({
        margin: "0px !important",
        fontSize: width < 500 ? "24px": "42px",
        textAlign: "end",
        letterSpacing: -1,
        marginRight: "20px !important"
    })

    const handle_change = e => setCoPsw(e.target.value)

    return(<Box sx={{ position: "relative", filter: "blur(0px)" }}>
        <DialogPsw state_open={open} onChange={handle_change}/>
        <Box sx={{
            position: "absolute",
            height: "30vh",
            width: "100%",
            background: "#f6db9b",
            zIndex: -3,
        }}></Box>
        <Grid container sx={{
            minHeight: "93vh",
            padding: "0px 30px",
        }}>        
            <Grid item xs={12} sm={12} md={9}>
                <Box sx={{
                    height: "30vh",
                    display: "grid",
                    alignContent: "end",
                    justifyContent: "start"
                }}>
                    <Grid container>
                        {width < 900 ? (<Grid item xs={4} sx={{display: "grid", alignContent: "end"}}>
                            <Imgcustome sx={{
                                    width: "56% !important",
                                    minWidth: "80px",
                                    borderRadius: "50%",
                                    margin: "30px 22%"
                                }} src={imgsrc}/>
                        </Grid>):null}
                        <Grid item xs={width < 900 ? 8:12} sx={{display: "grid", alignContent: "end"}}>
                            <Titulo>Milton Jehovani Ramos Pérez</Titulo>
                        </Grid>
                    </Grid>
                </Box>
                {width < 900 ? (<Box sx={{background: "#404040", padding: "20px 0px", borderRadius:"0px 0px 10px 10px"}}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "5px 0px"
                    }}>
                        <AlternateEmail sx={{
                            color: "white",
                            fontSize: "15px",
                            marginRight: "6px"
                        }}/>
                        <Parrafo sx={{color: ""}}>{info?.correo      ? info.correo: "example@gmail.com"}</Parrafo>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "5px 0px"

                    }}>
                        <LocalPhone sx={{
                            color: "white",
                            fontSize: "15px",
                            marginRight: "6px"
                        }}/>
                        <Parrafo sx={{color: ""}}>{info?.tel         ? info.tel: "8100000000"}</Parrafo>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "5px 0px"

                    }}>
                        <Place sx={{
                            color: "white",
                            fontSize: "15px",
                            marginRight: "6px"
                        }}/>
                        <Parrafo sx={{color: ""}}>{info?.direccion   ? info.direccion: "NaN"}</Parrafo>
                    </Box>
                </Box>):null}
                <Box>
                    <Parrafo sx={{
                        marginTop: "30px !important",
                        marginRight: "25px !important",
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "black !important",
                        textAlign: "start",
                        textDecoration: "underline",
                        marginBottom: "10px !important"
                    }}>Perfil Profesional</Parrafo>
                    <Parrafo sx={{color: "black", textAlign: "start", marginRight: "25px !important"}}>
                        Soy un <b>Ingeniero en Mecatrónica</b> con conocimientos más enfocados en programación, me gusta aprender constantemente nuevas tecnologías; frameworks, librerías y softwares.
                        Estoy certificado en <b>Sistema de Electrónica Automotriz</b>, <b>Mecánica de Precisión</b> y <b>Gestión Avanzada de Tecnologías de Información</b>.
                        Tengo más de 5 años en el ámbito de la programación, me apasionan los retos y trabajo en equipo. 
                    </Parrafo>
                </Box>
                <Box>
                    <Parrafo sx={{
                        marginTop: "30px !important",
                        marginRight: "25px !important",
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "black !important",
                        textAlign: "start",
                        textDecoration: "underline",
                        marginBottom: "15px !important"
                    }}>Experiencia</Parrafo>
                    <AccordionUsage experiencias={[
                        {
                            title: "Desarrollador full stack - JELP",
                            date: "Julio 2023 - Noviembre 2023",
                            desc: `Gestión de módulos de sistema crm desarrollado con PHP nativo,
                                    elaboración de webservice (CRUD) consultas a la db Mysql y desarrollo
                                    del frontend con javascript (Reactjs, nativo php con algunas librerías
                                    de js como Wizard).`
                        },{
                            title: "Desarrollador full stack (freelance) - Lavoignet",
                            date: "Julio 2021 - Actualidad",
                            desc: `Creación y gestión de sistemas TI, automatización de inventario de
                                    tiendas en shopify con express.js; sincronización de webhooks,
                                    programación de cronjobs y endpoints para api interna de la agencia.
                                    Gestión de instancias en aws, buckets s3 y administración de dominios,
                                    comunicación ssh entre ec2. Consumo de API meta para sincronizar
                                    chats de Whatsapp en sistema de la compañía, gestión de landing page
                                    con wordpress y shopify (liquid), versionamiento de código con github,
                                    sistemas desarrollados con React.js (hooks, states, forms) y Material-UI
                                    para importar componentes con estilos.`
                        },{
                            title: "Ing. Mantenimiento Predictivo - Daltile",
                            date: "Diciembre 2020 – Junio 2021",
                            desc: `Creación de reportes diarios y alertas de equipos con ayuda de python
                                    (Mathlab) y sensores phantom, monitorización de fallos de motores
                                    siemens y reportes de vibraciones con ayuda de sensores skf. Gestión
                                    de aplicaciónes de Microsoft; consumo de api’s de Microsoft con
                                    usuarios de outlook para inicio de sesión de la empresa.`
                        },{
                            title: "Arquitecto de soluciones - Totalcloud",
                            date: "Mayo 2020 – Noviembre 2020",
                            desc: `Creación y desarrollo de microservicios de AWS para clientes que
                                    deseaban alojar sus proyectos en la nube de Amazon, asesoría de
                                    aplicación de herramientas de G-suite para la gestión personal del
                                    usuario, desarrollo y gestión de la plataforma Bitrix24 CRM para la
                                    automatización de procesos de una empresa o negocio.`
                        },{
                            title: "Desarrollador – Grupo Cleber",
                            date: "Julio 2019 – Abril 2020",
                            desc: `Creación y mantenimiento de Landing, pages, sistemas y aplicaciones
                                    web, APIs, mantenimiento e integración de Bitrix (CRM). Manejo de Git
                                    para el control de versiones. Utilice Codeigniter como framework
                                    programando en PHP y para el Frontend Angular, Bootstrap.`
                        }
                        
                    ]}/>
                </Box>
                <Box>
                    <Parrafo sx={{
                        marginTop: "30px !important",
                        marginRight: "25px !important",
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: "black !important",
                        textAlign: "start"
                    }}>Actividades</Parrafo>
                    <AccordionUsage experiencias={[
                        {
                            title: "Torneo internacional de Robótica (Brasil 2019).",
                            desc: `Representamos a Nuevo León y México en el torneo de robótica latinoamericano
                                    en la categoría LARC. Desarrollamos un robot con la función de identificar
                                    contenedores por color y colocarlos en orden y en sus respectivos vehiculos
                                    (Barcos y tren).`
                        },{
                            title: "Power UP – Game Jam (2017).",
                            desc: `Hackaton donde desarrolláramos un
                                    programa basado en Python con el objetivo de contar personas diferenciando si
                                    entraban o salian de una tienda, para esto utilizamos la librería OpenCV, panda,
                                    mathlab, entre otros.`
                        },{
                            title: "Innovation Meet-up AXALTA (2017).",
                            desc: `Creación de reportes diarios y alertas de equipos con ayuda de python
                                    (Mathlab) y sensores phantom, monitorización de fallos de motores
                                    siemens y reportes de vibraciones con ayuda de sensores skf. Gestión
                                    de aplicaciónes de Microsoft; consumo de api’s de Microsoft con
                                    usuarios de outlook para inicio de sesión de la empresa.`
                        }
                    ]}/>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3} sx={{
                background: "#404040",
                padding: "20px 0px",
                borderRadius: "8px",
                margin: "10px 0px"
            }}>
                {width > 899?(<Box>
                    <Imgcustome sx={{
                        width: "56% !important",
                        borderRadius: "50%",
                        margin: "30px 22%"
                    }} src={imgsrc}/>
                    <Box sx={{margin: "0px 20px"}}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "5px 0px"
                    }}>
                        <AlternateEmail sx={{
                            color: "white",
                            fontSize: "15px",
                            marginRight: "6px"
                        }}/>
                        <Parrafo sx={{color: ""}}>{info?.correo      ? info.correo: "example@gmail.com"}</Parrafo>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "5px 0px"

                    }}>
                        <LocalPhone sx={{
                            color: "white",
                            fontSize: "15px",
                            marginRight: "6px"
                        }}/>
                        <Parrafo sx={{color: ""}}>{info?.tel         ? info.tel: "8100000000"}</Parrafo>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "5px 0px"

                    }}>
                        <Place sx={{
                            color: "white",
                            fontSize: "15px",
                            marginRight: "6px"
                        }}/>
                        <Parrafo sx={{color: ""}}>{info?.direccion   ? info.direccion: "NaN"}</Parrafo>
                    </Box>
                    </Box>
                    <Divider color="white" sx={{ margin: "15px 0px" }}/>
                </Box>):null}
                <Parrafo sx={{fontWeight: "bold", fontSize: "20px"}}>Lenguajes</Parrafo>
                {lenguaje?.length > 0 ? lenguaje.map((item, i_item) => {
                    let nivel_label = "Básico"
                    if(item?.percent && item.percent > 30 && item.percent <= 50){
                        nivel_label = "Medio"
                    }if(item?.percent && item.percent > 50 && item.percent < 85){
                        nivel_label = "Avanzado"
                    }if(item?.percent && item.percent > 85){
                        nivel_label = "Experto"
                    }

                    return(<Box key={i_item} sx={{margin: "0px 15px !important"}}>
                        <Grid container>
                            <Grid item xs={8}>
                                <Parrafo sx={{textAlign: "start"}}>{item?.name ? item.name: "default"}</Parrafo>
                            </Grid>
                            <Grid item xs={4}>
                                <Parrafo sx={{textAlign: "end"}}>{nivel_label}</Parrafo>
                            </Grid>
                        </Grid>
                        <Box sx={{
                            background: "#606060",
                            height: "16px",
                            padding: "2px 3px",
                            borderRadius: "50px",
                        }}>
                            <Box sx={{
                                width: `${item?.percent ? item.percent: 0}%`,
                                height: "11px",
                                borderRadius: "50px",
                                background: "#e9a406"
                            }}></Box>
                        </Box>
                    </Box>)
                }): null}
                <Divider color="white" sx={{ margin: "15px 0px" }}/>
                <Box sx={{
                    marginBottom: "20px"
                }}>
                    <Parrafo sx={{fontWeight: "bold", fontSize: "20px"}}>Tecnologías</Parrafo>
                    {tech?.length > 0 ? tech.map((item, i_item) => {
                        let nivel_label = "Básico"
                        if(item?.percent && item.percent > 30 && item.percent <= 50){
                            nivel_label = "Medio"
                        }if(item?.percent && item.percent > 50 && item.percent < 85){
                            nivel_label = "Avanzado"
                        }if(item?.percent && item.percent > 85){
                            nivel_label = "Experto"
                        }

                        return(<Box key={i_item} sx={{margin: "0px 15px !important"}}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <Parrafo sx={{textAlign: "start"}}>{item?.name ? item.name: "default"}</Parrafo>
                                </Grid>
                                <Grid item xs={4}>
                                    <Parrafo sx={{textAlign: "end"}}>{nivel_label}</Parrafo>
                                </Grid>
                            </Grid>
                            <Box sx={{
                                background: "#606060",
                                height: "16px",
                                padding: "2px 3px",
                                borderRadius: "50px",
                            }}>
                                <Box sx={{
                                    width: `${item?.percent ? item.percent: 0}%`,
                                    height: "11px",
                                    borderRadius: "50px",
                                    background: "#e9a406"
                                }}></Box>
                            </Box>
                        </Box>)
                    }): null}
                </Box>
                <Divider color="white" sx={{ margin: "15px 0px" }}/>
                <Box sx={{
                    marginBottom: "20px"
                }}>
                    <Parrafo sx={{fontWeight: "bold", fontSize: "20px"}}>Base de datos</Parrafo>
                    {dbs?.length > 0 ? dbs.map((item, i_item) => {
                        let nivel_label = "Básico"
                        if(item?.percent && item.percent > 30 && item.percent <= 50){
                            nivel_label = "Medio"
                        }if(item?.percent && item.percent > 50 && item.percent < 85){
                            nivel_label = "Avanzado"
                        }if(item?.percent && item.percent > 85){
                            nivel_label = "Experto"
                        }

                        return(<Box key={i_item} sx={{margin: "0px 15px !important"}}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <Parrafo sx={{textAlign: "start"}}>{item?.name ? item.name: "default"}</Parrafo>
                                </Grid>
                                <Grid item xs={4}>
                                    <Parrafo sx={{textAlign: "end"}}>{nivel_label}</Parrafo>
                                </Grid>
                            </Grid>
                            <Box sx={{
                                background: "#606060",
                                height: "16px",
                                padding: "2px 3px",
                                borderRadius: "50px",
                            }}>
                                <Box sx={{
                                    width: `${item?.percent ? item.percent: 0}%`,
                                    height: "11px",
                                    borderRadius: "50px",
                                    background: "#e9a406"
                                }}></Box>
                            </Box>
                        </Box>)
                    }): null}
                </Box>
                <Divider color="white" sx={{ margin: "15px 0px" }}/>
                <Box sx={{
                    marginBottom: "20px"
                }}>
                    <Parrafo sx={{fontWeight: "bold", fontSize: "20px"}}>Adicionales</Parrafo>
                    {adds?.length > 0 ? adds.map((item, i_item) => {
                        let nivel_label = "Básico"
                        if(item?.percent && item.percent > 30 && item.percent <= 50){
                            nivel_label = "Medio"
                        }if(item?.percent && item.percent > 50 && item.percent < 85){
                            nivel_label = "Avanzado"
                        }if(item?.percent && item.percent > 85){
                            nivel_label = "Experto"
                        }

                        return(<Box key={i_item} sx={{margin: "0px 15px !important"}}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <Parrafo sx={{textAlign: "start"}}>{item?.name ? item.name: "default"}</Parrafo>
                                </Grid>
                                <Grid item xs={4}>
                                    <Parrafo sx={{textAlign: "end"}}>{nivel_label}</Parrafo>
                                </Grid>
                            </Grid>
                            <Box sx={{
                                background: "#606060",
                                height: "16px",
                                padding: "2px 3px",
                                borderRadius: "50px",
                            }}>
                                <Box sx={{
                                    width: `${item?.percent ? item.percent: 0}%`,
                                    height: "11px",
                                    borderRadius: "50px",
                                    background: "#e9a406"
                                }}></Box>
                            </Box>
                        </Box>)
                    }): null}
                </Box>
            </Grid>
        </Grid>
    </Box>)
}

export default Profile
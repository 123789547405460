import { Box, Divider, Grid, Typography } from "@mui/material";
import imgsrc1 from '../../img/img-1-1.png'
import imgsrc2 from '../../img/img-1-2.jpeg'
import imgsrc3 from '../../img/img-1-3.jpeg'

import rsr1 from '../../img/aws.png'
import rsr2 from '../../img/nodejs.png'
import rsr3 from '../../img/mongodb.png'
import rsr4 from '../../img/python.png'

import vidsrc from '../../videos/banner.mp4'
import styled from "styled-components";
import CardCustom from "./CardCustom";
import { useState } from "react";

const Home = () => {
    const heightadjust = 70
    const [post, setPost] = useState([
        {
            src:imgsrc2,
            title:"Innovation Meet-Up",
            date:"31 de Marzo del 2017",
            desc:"Torneo institucional Innovation Meet-Up, concurso a nivel nacional organizado por la institución Tecmilenio."
        },{
            src:imgsrc1,
            title:"Torneo Robotica LARC (Guadalajara)",
            date:"21 de Marzo del 2019",
            desc:"Torneo de robótica en la categoría LARC OPEN Guadalajara 2019"
        },{
            src:imgsrc3,
            title:"Torneo Robotica LARC (Brasil)",
            date:"18 de Octubre del 2019",
            desc:"Torneo Internacional de robótica en la categoría LARC OPEN Brasil 2019"
        },
    ])

    const Img = styled('img')({
        width: "100% !important",
        height: `${heightadjust}vh`,
        boxShadow: "0px 1px 21px -8px black",
        position: "absolute",
        background: "black !important",
        top: "0px",
        zIndex:-1,
        left: "0px",
        opacity: 0.35
    })

    const Btn = styled('input')({
        width: "100%",
        borderRadius: "50px",
        background: "#007ab6",
        outline: "none",
        border: "none",
        width: "200px",
        marginTop: "10px",
        padding: "15px 20px",
        cursor: "pointer",
        fontSize: "12px",
        fontWeight: "bolder",
        color: "white",
    })

    const BtnCv = styled('input')({
        width: "100%",
        borderRadius: "50px",
        background: "white",
        outline: "none",
        border: "none",
        width: "200px",
        marginTop: "10px",
        padding: "15px 20px",
        cursor: "pointer",
        fontSize: "12px",
        fontWeight: "bolder",
        color: "#007ab6",
    })


    const Title = styled('p')({
        position: "absolute",
        margin: "0px",
        fontWeight: "bold",
        fontSize: "40px",
        color: "white"
    })

    const Video = styled('video')({
        opacity: 0.6,
        background: "#1230a8",
        position: "absolute",
        right: 0,
        bottom: 0,
        minWidth: "100%", 
        minHeight: "65vh",
        objectFit: "fill"
    })

    const styleGrid = {
        display: "grid",
        alignContent: "center",
        justifyContent: "center"
    }

    return(<Box>
            <Box>
                <Box sx={{
                    position: "relative",
                    height: "65vh !important",
                    background: "black"
                }}>
                    <Video autoplay="autoplay" loop muted>
                        <source src={vidsrc} type="video/mp4"></source>
                    </Video>
                    <Box sx={{
                        position: "absolute",
                        bottom: 0,
                        zIndex: 3,
                        margin: "20px"
                    }}>
                        <Typography variant="h3" sx={{color: "white"}}>Soluciones TI</Typography>
                        <Typography variant="h5" sx={{color: "white"}}>Con más de 5 años de experiencia con especialidad en programación.</Typography>
                        <Btn onClick={()=>window.location.href = "/profile"} type="button" value="VER CV"/>
                    </Box>
                </Box>
                <Box sx={{padding: "20px"}}>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={6} md={3} sx={styleGrid}>
                            <img src={rsr1} width="100% !important" style={{ maxWidth: "120px", margin: "30px 0px" }}/>
                        </Grid>
                        <Grid xs={12} sm={6} md={3} sx={styleGrid}>
                            <img src={rsr2} width="100% !important" style={{ maxWidth: "120px", margin: "30px 0px" }}/>
                        </Grid>
                        <Grid xs={12} sm={6} md={3} sx={styleGrid}>
                            <img src={rsr3} width="100% !important" style={{ maxWidth: "110px", margin: "30px 0px" }}/>
                        </Grid>
                        <Grid xs={12} sm={6} md={3} sx={styleGrid}>
                            <img src={rsr4} width="100% !important" style={{ maxWidth: "90px", margin: "30px 0px" }}/>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{
                    height: "20vh",
                    background: "#007ab6",
                    display: "grid",
                    alignContent: "center",
                    paddingLeft: "20px"
                }}>
                    <Typography sx={{fontSize: "13px", color: "white", fontWeight: "bold"}}>
                        Información personal
                    </Typography>
                    <Typography sx={{fontSize: "12px", color: "white"}}>
                        Extensos conocimientos en programación, elaborando cada proyecto en base a la metodología SCRUM. 
                    </Typography>
                    <BtnCv  onClick={()=>window.location.href = "/profile"} type="button" value="VER CV"/>
                </Box>
                <Box sx={{padding: "20px"}}>
                    <Typography variant="h5">Actividades Extracurriculares</Typography>
                    <Divider sx={{
                        margin: "10px 0px 30px 0px !important"
                    }}/>
                    <Grid container spacing={2}>
                        {post?.length > 0 ? post.map(item=>(<Grid item xs={12} sm={6} md={4}>
                            <CardCustom
                            src={item.src}
                            title={item.title}
                            date={item.date}
                            desc={item.desc}
                        /></Grid>)):null}
                    </Grid>
                </Box>
                <Box sx={{background: "black"}}>
                    <Typography sx={{fontSize: "12px", color: "white", textAlign: "center", padding: "15px 0px"}}>Desarrollado por wexnot, 2024.</Typography>
                </Box>
            </Box>
        
    </Box>)
}

export default Home;
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, styled } from '@mui/material';
import HorizontalNonLinearStepper from './StepperCustom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "8px",
  p: "15px 20px",
};

export default function DialogPsw({state_open, onChange}) {

    React.useEffect(()=>{
        setOpen(state_open ? true: false)
    },[state_open])
    const [open, setOpen] = React.useState(true);
    const [contactoOpen, setContactoOpen] = React.useState(false);

    const Btn = styled('input')({
        width: "100%",
        borderRadius: "8px",
        outline: "none",
        border: "1px solid gray",
        marginTop: "10px",
        padding: "5px 10px" 
    })

    const abrirLink = () => {
        window.location.href = "https://api.whatsapp.com/send?phone=5218125230359&text=Hola%20buen%20d%C3%ADa%2C%20me%20interesa%20un%20acceso%20para%20visualizar%20tu%20perfil%2C%20saludos!"
        console.log("kldklfsdlks")
    }
    
    const BolderP = styled('b')({
        textDecoration: "underline",
        color: "blue",
        cursor: "pointer !important",
    })

    return (<div>
        {/* <Modal
            open={contactoOpen}
            onClose={e=>setContactoOpen(false)}
            sx={{
                backdropFilter: "blur(11px)"
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Contacto
                </Typography>
                <Box>
                    <HorizontalNonLinearStepper/>
                </Box>
                <Grid container>
                    <Grid item xs={6} sx={{
                        display: "grid",
                        justifyContent: "start"
                    }}>
                    </Grid>
                    <Grid item xs={6} sx={{
                        display: "grid",
                        justifyContent: "end"
                    }}>
                        <Btn onClick={()=>{setContactoOpen(false)}} sx={{
                            cursor: "pointer",
                            fontSize: "12px",
                            border: "0px",
                            boxShadow: "0px 1px 4px -2px black"
                        }} type="button" value="Cancelar"/>
                    </Grid>
                </Grid>
            </Box>
        </Modal> */}
        <Modal
            open={open}
            sx={{
                backdropFilter: "blur(11px)"
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Palabra Clave
                </Typography>
                <Typography id="modal-modal-description" sx={{ fontSize: "12px" }}>
                    Ingrese la palabra clave, si no tiene una contacte al <BolderP onClick={abrirLink}>administrador</BolderP> para que le proporcione una.
                </Typography>
                <Box sx={{
                    "& input": {
                        fontSize: "12px !important",
                        padding: "10px 15px",
                        borderRadius: "8px !important",
                        letterSpacing: "2px"
                    }
                }}>
                    <TextField
                        type="password"
                        sx={{
                            width: "100% !important",
                            marginTop: "10px",
                        }}
                        onChange={onChange}
                    />
                </Box>
                <Grid container>
                    <Grid item xs={6} sx={{
                        display: "grid",
                        justifyContent: "start"
                    }}>
                        {/* <Btn onClick={()=>{setContactoOpen(true)}} sx={{
                            cursor: "pointer",
                            fontSize: "12px",
                            border: "0px",
                            boxShadow: "0px 1px 4px -2px black"
                        }} type="button" value="Solicitar clave"/> */}
                    </Grid>
                    <Grid item xs={6} sx={{
                        display: "grid",
                        justifyContent: "end"
                    }}>
                        <Btn onClick={()=>{
                            window.location.href = "/"
                        }} sx={{
                            cursor: "pointer",
                            fontSize: "12px",
                            border: "0px",
                            boxShadow: "0px 1px 4px -2px black"
                        }} type="button" value="Regresar"/>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    </div>);
}
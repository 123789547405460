import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Box, styled } from '@mui/material';

export default function AccordionUsage({experiencias}) {
    const LblFecha = styled('p')({
        margin: "0px !important"
    })
  return (<Box sx={{
        marginRight: "25px !important",
        marginBottom: "20px !important"
    }}>
        {experiencias?.length > 0 ? experiencias.map((experiencia, i_exp) => (<Accordion key={i_exp}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Box>
                    <LblFecha sx={{
                        fontSize: "10px !important",
                    }}>{experiencia?.date ? experiencia.date:null}</LblFecha>
                    <LblFecha sx={{
                        fontSize: "12px !important",
                        fontWeight: "bold"
                    }}>{experiencia?.title ? experiencia.title:null}</LblFecha>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{fontSize: "12px"}}>{experiencia.desc}</AccordionDetails>
        </Accordion>)):null}
    </Box>);
}
